import React, { useEffect, useRef, useState } from 'react'
import { animated, useTrail } from 'react-spring'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import TimelineYears from './_TimelineYears'

import NossaTrajetoriaJSON from '../../assets/data/NossaTrajetoria.json'

import * as S from './style'
type carouselDataProps = {
  id: number;
  year: string;
  description: string;
}

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const NossaTrajetoria = () => {
  const [ carouselIndex, setCaroulselIndex ] = useState(0)

  function changeCaroulselSelected (nextSlide: number) {
    setCaroulselIndex(nextSlide)
  }

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
    }
  }, [ scroll ])

  return (
    <S.Section ref={sectionRef} className='pt-1'>
      <animated.div style={animatedSection[0]} className='row mx-0 py-5'>
        <div className='col-12 col-md-11 col-lg-10 col-xl-6 mx-auto align-items-center text-center px-4'>
          <h2 className='font-citrina fs-28 fs-md-40 fs-lg-48 lh-33 lh-md-44 lh-lg-52 mb-4'> Nossa trajetória</h2>
          <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 text-grayscale--500'>Numa jornada com grandes transformações, cada momento é importante para continuarmos crescendo e inovando.</p>
        </div>
      </animated.div>
      <div className='line' />
      <animated.div style={animatedSection[1]}>
        <TimelineYears carouselIndexData={carouselIndex} NossaTrajetoriaJSON={NossaTrajetoriaJSON} />
      </animated.div>
      <animated.div style={animatedSection[2]} className='col-12 mx-auto px-0 pb-5'>
        <DefaultCarousel
          sm={{
            items: 1,
          }}
          md={{
            items: 1,
          }}
          lg={{
            items: 1,
          }}
          xl={{
            items: 1,
          }}
          beforeChange={(nextSlide: number) => changeCaroulselSelected(nextSlide)}
        >
          {
            NossaTrajetoriaJSON.map((item: carouselDataProps, index: number) => (
              <div className='container' key={index}>
                <div className='row'>
                  <div className='col-9 col-md-8 px-0 mx-auto text-center' key={index}>
                    <S.Desc
                      className='text-grayscale--500 fs-18 lh-22'
                      dangerouslySetInnerHTML={{ __html: `${carouselIndex === item.id ? item.description : ''}` }}
                    />
                  </div>
                </div>
              </div>
            ))
          }
        </DefaultCarousel>
      </animated.div>
    </S.Section>
  )
}

export default NossaTrajetoria
