import { areia, laranja, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${areia};

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
    color: ${laranja.terra}
  }
`

export const LinkButton = styled.a`
  border-radius: 8px;
  background: ${orange[500]};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  height: 48px;
  width: 100%;
  margin-top: 21px;
  
  &:hover {
    opacity: 0.9;
    color: ${white};
  }
`
