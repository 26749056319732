import { device } from 'src/styles/breakpoints'
import { orange, primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background: ${primary[500]};

  h2 {
    color: ${orange.dark};
    font-size: 28px;
    line-height: 33.6px;

    @media ${device.tablet} {
      font-size: 40px;
      line-height: 44px;
    }
    @media ${device.desktopLG} {
      font-size: 48px;
      line-height: 52.8px;
    }
    @media ${device.desktopXXXL} {
      font-size: 62px;
      line-height: 75px;
    }
  }
`
