import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { IconsSwitchProps } from 'src/components/IconsSwitch/types'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import * as S from './styles'

export type OrangeCardProps = {
  title: string;
  description: string;
  downloadOrEmail: string;
  icon: IconsSwitchProps;
  dataLayerParams?: IDataLayerParams;
  redirectUrl?: string;
}

const OrangeCard = ({ title, description, downloadOrEmail, icon, dataLayerParams, redirectUrl }: OrangeCardProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Card
      className='card d-flex flex-column justify-content-between pl-4 pr-5'
    >
      <div>
        <div className='card__icon'>
          <IconsSwitch
            icon={icon.icon}
            width='24'
            height='24'
            color='white'
            lib={icon.lib}
            className={`${icon.className ?? ''}`}
            customPath={icon.customPath}
          />
        </div>
        <h3
          className='card__title text-white fs-24 fs-md-28 fs-lg-40 lh-17 lh-md-33 lh-lg-44 fw-500 font-citrina'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='card__description text-white fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-20 lh-lg-22'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      { redirectUrl
        ? (
          <span
            className='card__links'
          >
            <a
              href={redirectUrl}
              onClick={() => {
                  dataLayerParams && sendDatalayerEvent(dataLayerParams)
              }}
              dangerouslySetInnerHTML={{ __html: downloadOrEmail }}
            />
          </span>
        )
        : (
          <span
            className='card__links text-white'
            dangerouslySetInnerHTML={{ __html: downloadOrEmail }}
          />
        )
      }

    </S.Card>
  )
}

export default OrangeCard
