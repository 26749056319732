import React, { useEffect, useRef } from 'react'
import { animated, useSpring, useTrail } from 'react-spring'
import ImageWebp from 'src/components/ImageWebp'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import EmPoucosCliquesJSON from '../../assets/data/EmPoucosCliques.json'

import * as S from './style'

type ListType = {
  icon: string;
  text: string;
}

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const EmPoucosCliques = () => {
  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  return (
    <S.Section ref={sectionRef} className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-xl-1 order-md-last pl-md-4'>
            <animated.h2
              style={animatedSection[1]}
              className='font-citrina text-white fw-500 mb-3 mb-md-4 mb-xl-5'
            >
              Resolva tudo em poucos cliques.
            </animated.h2>
            <animated.div style={animatedSection[0]} className='icon-section'>
              {EmPoucosCliquesJSON.map((item: ListType) => (
                <div key={item.icon} className='d-flex align-items-center mb-2 mb-xl-4'>
                  <OrangeIcon size='MD' color='#ffffff' icon={item.icon} />
                  <p className='text-white fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 fw-400 mb-0 ml-3'>{item.text}</p>
                </div>
              ))}
            </animated.div>
          </div>
          <animated.div style={animatedImage} className='col-12 col-md-6 d-flex justify-content-center justify-content-lg-start mt-4 mt-md-0'>
            <ImageWebp
              arrayNumbers={[ 312, 366, 459, 553 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-app-conta/image.webp'
              altDescription='Homem jovem usando seu smartphone.'
            />
          </animated.div>
        </div>
      </div>
    </S.Section>
  )
}

export default EmPoucosCliques
