import { device } from "src/styles/breakpoints"
import { white } from "src/styles/colors"
import * as newColors from 'src/styles/newColors'
import styled from "styled-components"

export const Card = styled.article`
  background-color: ${newColors.primary[500]};
  height: 249px;
  
  @media ${device.tablet}{
    height: 289px;
  }
  @media ${device.desktopLG}{
    height: 320px;
  }

  .card{
    &__icon{
      margin-bottom: 24px;
    }
    &__title {
      letter-spacing: 0;
    }

    &__description, &__links {
      letter-spacing: 0; 
      
      a {
        color: ${white};
        text-decoration: underline;
      }
    }
  }
`
