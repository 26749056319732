import React, { useEffect, useRef } from 'react'
import { animated, useSpring, useTrail } from 'react-spring'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'

import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'

import PremiosReconhecimentos from './../../assets/data/premios-e-reconhecimentos.json'

import { WIDTH_LG, WIDTH_MD, WIDTH_XL } from 'src/styles/breakpoints'

import * as S from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const images = {
  sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-nasdaq-360/image.webp',
  md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-nasdaq-768/image.webp',
  lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/nasdaq-sobre-o-inter/image.webp',
  xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-nasdaq-1440/image.webp',
}

const getImageByWidth = (width: number) => {
  if (width < WIDTH_MD) return images.sm
  if (width < WIDTH_LG) return images.md
  if (width < WIDTH_XL) return images.lg
  return images.xl
}

const PremiosEReconhecimentos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  return (
    <S.Section ref={sectionRef} className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <animated.div className='col-12'>
            <h2
              style={animatedSection[0]}
              className='font-citrina text-grayscale--500 fs-28 lh-33 fs-md-40 fs-lg-48 lh-md-44 lh-lg-52 mb-3 text-md-center'
            >
              Prêmios e reconhecimentos
            </h2>
            <p style={animatedSection[0]} className='fs-16 lh-20 fs-lg-18 text-grayscale--500 mb-md-4 text-md-center'>
              No Inter, inovamos todos os dias para que nossos clientes tenham uma vida financeira mais eficiente e inteligente.
              É com muito orgulho que vemos essa missão ser traduzida em reconhecimento.
            </p>
          </animated.div>
        </div>
        <div className='row mt-4 mt-md-0'>
          <animated.div style={animatedImage} className='col-12 col-md-6 col-lg-4 col-xl-5 mb-4 mb-md-0 text-center'>
            <ImageWebp
              pathSrc={getImageByWidth(width)}
              altDescription='Apresentação Inter&Co no telão da Nasdaq na Times Square'
              arrayNumbers={[ 312, 316, 265, 381, 381 ]}
              arrayNumbersHeight={[ 306, 554, 444, 497.32, 497.32 ]}
            />
            <p className='fs-14 fs-md-16 fs-lg-20 fs-xl-23 lh-17 lh-md-20 lh-lg-24 lh-xl-28 mt-3 mb-md-0 fw-500 text-center text-grayscale--500'>Nasdaq Listed</p>
          </animated.div>
          <animated.div style={animatedSection[1]} className='col-12 col-md-6 col-lg-8 col-xl-7'>
            <div className='row'>
              {
                PremiosReconhecimentos.map((items: { description: string; logoImageUrl: string }, index: number) => (
                  <div className='col-6 col-lg-4' key={index}>
                    <S.LogoCards>
                      <ImageWebp
                        pathSrc={items.logoImageUrl}
                        altDescription={items.description}
                        arrayNumbers={[ 105, 120, 135, 150, 150 ]}
                        arrayNumbersHeight={[ 70, 80, 90, 100, 100 ]}
                      />
                      <S.DescriptionCards>
                        {items.description}
                      </S.DescriptionCards>
                    </S.LogoCards>
                  </div>
                ))
              }
            </div>
          </animated.div>
          <S.LinkButton
            href='https://blog.inter.co/premios-do-inter/?_gl=1*1dxf7hy*_gcl_au*MTQ5MTI2MTIxMi4xNzI2NjEwMzYx'
            target='_blank'
            title='Confira todos os nossos prêmios'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_4',
                section_name: 'Prêmios e reconhecimentos',
                element_action: 'click button',
                element_name: 'Confira todos os nossos prêmios',
                redirect_url: 'https://investors.inter.co/',
              })
            }}
          >
            Confira todos os nossos prêmios
          </S.LinkButton>
        </div>
      </div>
    </S.Section>
  )
}

export default PremiosEReconhecimentos
