import React, { useEffect, useRef } from 'react'
import { animated, useSpring, useTrail } from 'react-spring'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'

import ImageWebp from 'src/components/ImageWebp'

import * as S from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const Sustentabilidade = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  const imgMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/nasdaq-inter-img-sm/image.webp'
  const imgXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/nasdaq-inter-img-xl/image.webp'

  return (
    <S.Section ref={sectionRef} className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <animated.div style={animatedImage} className='col-12 col-md-6 offset-lg-1 d-flex justify-content-center justify-content-md-end order-md-last'>
            <ImageWebp
              pathSrc={width < WIDTH_MD ? imgMobile : imgXL}
              altDescription='Mulher sorrindo e mexendo no celular'
              arrayNumbers={[ 308, 340, 456, 545 ]}
            />
          </animated.div>
          <animated.div style={animatedSection[1]} className='col-12 col-md-6 col-lg-5 mt-2 mt-md-0 pr-md-4'>
            <h2 className='fs-28 fs-md-40 fs-lg-48 lh-33 lh-md-44 lh-lg-52 mt-3 mt-md-0 mb-2'>Sustentabilidade</h2>
            <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-19 lh-lg-22 fw-400 text-grayscale--500 mt-3'>
              Gerar valor para os nossos clientes e para a sociedade é nosso compromisso. Para isso, construímos todos os
              dias um negócio cada vez mais sustentável, responsável e alinhado aos pilares: Finanças, Meio Ambiente, Social
              e Governança. Conheça nossos esforços e estratégia ESG.
            </p>
            <S.LinkButton
              target='_blank'
              href='https://investors.inter.co/esg/'
              title='Acesse nosso Relatório Anual'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_5',
                  section_name: 'Sustentabilidade',
                  element_action: 'click button',
                  element_name: 'Acesse nosso Relatório Anual',
                  redirect_url: 'https://investors.inter.co/esg/',
                })
              }}
            >
              Acesse nosso Relatório Anual
            </S.LinkButton>
          </animated.div>
        </div>
      </div>
    </S.Section>
  )
}

export default Sustentabilidade
