import { device } from 'src/styles/breakpoints'
import { areia, grayscale, laranja, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  min-height: 673px;
  background-color: ${areia};

  @media ${device.tablet} {
    min-height: 928px;
  }
  @media ${device.desktopLG} {
    min-height: 835px;
  }
  @media ${device.desktopXL} {
    min-height: 894px;
  }

  h2 {
    color: ${laranja.terra};
  }
`

export const LinkButton = styled.a`
  border-radius: 8px;
  background: ${orange[500]};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  height: 48px;
  width: 100%;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;

  @media ${device.tablet} {
    width: 600px;
  }
  @media ${device.desktopXL} {
    margin-top: 16px;
  }

  &:hover {
    opacity: 0.9;
    color: ${white};
  }
`

export const LogoCards = styled.div`
  max-width: 100%;
  background: ${white};
  min-height: 175px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media ${device.tablet} {
    min-height: 180px;
    border-radius: 15px;
  }
  @media ${device.desktopLG} {
    border-radius: 20px;
    min-height: 225px;
    margin-bottom: 24px;
  }
  @media ${device.desktopXL} {
    min-height: 253px;
  }
`

export const DescriptionCards = styled.p`
  color: ${grayscale[500]};
  font-size: 12px;
  line-height: 14.52px;
  text-align: center;
  margin-top: 10px;
  padding: 0 10px;
  margin-bottom: 0;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
    padding: 0 5px;
  }
  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 20px;
    margin-top: 13px;
  }
`
