import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import EmPoucosCliques from './sections/em-poucos-cliques/_index'
import FiquePorDentro from './sections/fique-por-dentro/_index'
import Hero from './sections/hero/_index'
import ImprensaEParcerias from './sections/imprensa-e-parcerias/_index'
import NossaTrajetoria from './sections/nossa-trajetoria/_index'
import PremiosReconhecimentos from './sections/premios-e-reconhecimentos/_index'
import SuperAppFinanceiroCompleto from './sections/super-app-financeiro-completo/_index'
import Sustentabilidade from './sections/sustentabilidade/_index'

import pageContext from './pageContext.json'

const OInter = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <EmPoucosCliques />
      <SuperAppFinanceiroCompleto />
      <PremiosReconhecimentos />
      <Sustentabilidade />
      <NossaTrajetoria />
      <FiquePorDentro />
      <ImprensaEParcerias />
    </Layout>
  )
}

export default OInter
